import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ExpandItemContentDirective } from '@x/dashboard/core/components/expand-item/expand-item-content.directive';

@Component({
  selector: 'xd-expand-item',
  templateUrl: 'expand-item.component.html',
  styleUrls: ['expand-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xd-expand-item',
  },
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          visibility: 'hidden',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
          visibility: 'visible',
        }),
      ),
      transition('collapsed <=> expanded', [animate('150ms ease')]),
    ]),
  ],
})
export class ExpandItemComponent {
  @HostBinding('class.open')
  @Input()
  isExpanded = false;

  @Input()
  icon?: IconProp | null;

  @ContentChildren(ExpandItemContentDirective, { descendants: true })
  contentTemplates: QueryList<ExpandItemContentDirective>;

  @Output()
  expandChanged = new EventEmitter<boolean>();

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.expandChanged.emit(this.isExpanded);
  }
}
