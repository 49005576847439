import { fileExtension } from './file';
import { getUrlExtension } from './url';

export const enum MediaType {
  VIDEO = 'video',
  IMAGE = 'image',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
}

export interface MediaValidation {
  imageFormats: string[];
  imageMaxSize: number;
  videoFormats: string[];
  videoMaxSize: number;
  videoAllowed: boolean;
}

export const IMAGE_EXTENTIONS = [
  'gif',
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'tiff',
  'eps',
  'psd',
  'webp',
  'svg',
  'webm',
];

export const VIDEO_EXTENTIONS = ['flv', 'mov', 'mkv', 'mp4', 'mpd', 'ogv', 'webm'];

export const IMAGE_MIMES = [
  'image/bmp',
  'image/gif',
  'image/ief',
  'image/jpeg',
  'image/pipeg',
  'image/svg+xml',
  'image/tiff',
  'image/x-icon',
  'image/webp',
  'image/png',
];

export const VIDEO_MIMES = [
  'video/mpeg',
  'video/mp4',
  'video/quicktime',
  'video/x-la-asf',
  'video/x-ms-asf',
  'video/x-msvideo',
  'video/x-sgi-movie',
  'video/webm',
  'video/mov',
  'video/avi',
];
const YOUTUBE_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
const VIMEO_REGEX =
  /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

const YOUTUBE_URL_REGEX = /^https:\/\/(www\.)?(youtube\.com|youtu\.?be)\/.+$/i;
const VIMEO_URL_REGEX =
  /^https:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)$/i;

export function getMediaTypeFromExt(ext: string): MediaType {
  if (IMAGE_EXTENTIONS.includes(ext)) return MediaType.IMAGE;
  if (VIDEO_EXTENTIONS.includes(ext)) return MediaType.VIDEO;
  console.warn(`getMediaTypeFromExt: could not determine media type for ext '${ext}'`);
  return MediaType.IMAGE;
}

export function getMediaTypeFromMime(mime: string): MediaType {
  if (IMAGE_MIMES.includes(mime)) return MediaType.IMAGE;
  if (VIDEO_MIMES.includes(mime)) return MediaType.VIDEO;
  console.warn(`getMediaTypeFromExt: could not determine media type for mime type '${mime}'`);
  return MediaType.IMAGE;
}

export function getMediaTypeFromUrl(url: string): MediaType | null {
  if (YOUTUBE_URL_REGEX.test(url)) return MediaType.YOUTUBE;
  if (VIMEO_URL_REGEX.test(url)) return MediaType.VIMEO;

  const ext = getUrlExtension(url);

  if (ext) {
    if (IMAGE_EXTENTIONS.includes(ext)) return MediaType.IMAGE;
    if (VIDEO_EXTENTIONS.includes(ext)) return MediaType.VIDEO;
  }

  return null;
}

export function getYoutubeVideoIdFromUrl(url: string) {
  let match = url.match(YOUTUBE_REGEX);
  return match && match[7]?.length == 11 ? match[7] : null;
}

export function getYoutubeVideoThumbnailFromUrl(url: string, imageName: string = 'hqdefault') {
  const videoId = getYoutubeVideoIdFromUrl(url);
  return `https://img.youtube.com/vi/${videoId}/${imageName}.jpg`;
}

export function getVimeoVideoIdFromUrl(url: string) {
  let match = url.match(VIMEO_REGEX);
  return match && match[1] ? match[1] : null;
}

export function getVimeoVideoThumbnailFromUrl(url: string, imageName: string = '640') {
  const videoId = getVimeoVideoIdFromUrl(url);
  return `http://i.vimeocdn.com/video/${videoId}_${imageName}.webp`;
}

export function validateFile(file: File, validation: MediaValidation) {
  let validationErrors = [];

  let fileExt = fileExtension(file.name);

  if (!fileExt) {
    validationErrors.push(`${file.name} is not a valid file type.`);
    return validationErrors;
  }

  let isVideo = validation.videoFormats.includes(fileExt);

  if (!validation.imageFormats.includes(fileExt) && !isVideo) {
    validationErrors.push(`${file.name} is not a valid file type.`);
  }

  if (isVideo) {
    if (!validation.videoAllowed) validationErrors.push(`${file.name} video file not allowed.`);
    if (file.size > validation.videoMaxSize)
      validationErrors.push(`${file.name} exceeds the maximum file size for videos.`);
  } else {
    if (file.size > validation.imageMaxSize)
      validationErrors.push(`${file.name} exceeds the maximum file size for images.`);
  }

  return validationErrors;
}
