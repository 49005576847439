import { Time } from '@angular/common';
import { DateTime, DateTimeOptions } from 'luxon';

export function coerceDateTime(value: any, options?: DateTimeOptions): DateTime<true> | null {
  let dt: DateTime | null = null;
  if (DateTime.isDateTime(value)) {
    dt = value;
  } else if (typeof value === 'string') {
    dt = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss', options);
    if (!dt.isValid) {
      dt = DateTime.fromISO(value, options);
    }
  } else if (value instanceof Date) {
    dt = DateTime.fromJSDate(value, options);
  } else if (typeof value === 'number') {
    dt = DateTime.fromMillis(value, options);
  }

  if (!dt || !dt.isValid) {
    return null;
  }

  if (options?.zone) {
    return dt.setZone(options.zone) as DateTime<true>;
  }

  return dt;
}

export function coerceTimeValue(value: any): Time | null {
  if (!value) return null;
  if (typeof value === 'object' && 'hours' in value && 'minutes' in value) return value;
  if (typeof value !== 'string') return null;
  if (value.indexOf(':') < 0) return null;
  const [hours, minutes] = value.split(':').map((a) => Number(a));
  return {
    hours,
    minutes,
  };
}
